import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import Root from "./Root/Root";
import Sunglasses from "./Sunglasses/Sunglasses";
import reportWebVitals from "./reportWebVitals";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Main from "./Main/Main";

import aboutMd from "./content/about.md";
import algoratMd from "./content/algorat.md";
import googleMd from "./content/google.md";
import cmuMd from "./content/cmu.md";
import p5Md from "./content/p5.md";
import miscMd from "./content/misc.md";

const articleMapping: { [id: string]: string } = {
  about: aboutMd,
  algorat: algoratMd,
  google: googleMd,
  cmu: cmuMd,
  p5: p5Md,
  misc: miscMd,
};

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Sunglasses /> },
      {
        path: ":page",
        element: <Main />,
        loader: async ({ params }) => {
          const id = params.page;
          if (!id) return null;

          const src = articleMapping[id];
          if (!src) {
            return null;
          }

          const rawData = await fetch(src);
          const textResponse = await rawData.text();
          return textResponse;
        },
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
