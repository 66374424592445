import "./Navigation.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function HighlightableLink({
  route,
  ariaLabel,
  children,
}: {
  route: string;
  ariaLabel?: string;
  children: any;
}) {
  const page = useParams()?.page;
  const highlighted = page === route;

  return (
    <Link
      aria-label={ariaLabel}
      className={highlighted ? "current" : ""}
      to={route}
    >
      {children}
    </Link>
  );
}

function Navigation() {
  return (
    <nav className="left-panel">
      <h1>
        <HighlightableLink route="/">
          Connie Ye
          <span className="visually-hidden">Back to landing page</span>
        </HighlightableLink>
      </h1>
      <section>
        <h2 aria-label="General links">here is my...</h2>
        <ul>
          <li>
            <HighlightableLink route="about">about me info</HighlightableLink>
          </li>
          <li>
            <a
              href="https://www.instagram.com/c0n5tants"
              target="_blank"
              rel="noreferrer"
            >
              art instagram
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2 aria-label="My projects">i've worked...</h2>
        <ul>
          <li>
            <HighlightableLink
              ariaLabel="rat games with friends"
              route="algorat"
            >
              on rat games with friends
            </HighlightableLink>
          </li>
          <li>
            <HighlightableLink route="google">at Google</HighlightableLink>
          </li>
          <li>
            <HighlightableLink route="cmu">
              at Carnegie Mellon
            </HighlightableLink>
          </li>
          <li>
            <HighlightableLink route="p5" ariaLabel="the P-five JS editor">
              on the p5.js editor
            </HighlightableLink>
          </li>
          <li>
            <HighlightableLink
              ariaLabel="miscellaneous other projects"
              route="misc"
            >
              on ???
            </HighlightableLink>
          </li>
        </ul>
      </section>
    </nav>
  );
}

export default Navigation;
