import "./Main.scss";
import { useLoaderData, useParams } from "react-router-dom";
import Markdown from "react-markdown";

function Main() {
  const content = useLoaderData() as string;
  const page = useParams()?.page;

  let innerContent;
  if (!content || !page || page === "") {
    innerContent = (
      <div className="content not-found">
        <p>Hm! We couldn't find that page.</p>
      </div>
    );
  } else {
    innerContent = (
      <div className={`content ${page}`} aria-live="polite">
        <Markdown>{content}</Markdown>
      </div>
    );
  }

  return <main>{innerContent}</main>;
}

export default Main;
