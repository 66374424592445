import "./Sunglasses.scss";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import defaultAsciiTxt from "../assets/ascii/default.txt";
import smileAsciiTxt from "../assets/ascii/smile.txt";
import frownAsciiTxt from "../assets/ascii/frown.txt";
import { useMeasure } from "@uidotdev/usehooks";
import { useState, useRef } from "react";
import sunglassesSrc from "../assets/ascii/sunglasses.webp";
import sunglassesSmileSrc from "../assets/ascii/sunglasses_smile.webp";

const queryClient = new QueryClient();

enum GameState {
  DEFAULT,
  SMILE,
  FROWN,
}

async function fetchAscii(src: string): Promise<string> {
  const response = await fetch(src);
  return response.text();
}

function getFontSize(width: number, height: number) {
  return (width * 2) / 183;
}

function SunglassesMain() {
  const [isSGrabbing, setIsSGrabbing] = useState(false);
  const [sPosition, setSPosition] = useState([-15, -15]);
  const [sPositionOffset, setSPositionOffset] = useState([0, 0]);
  const [gameState, setGameState] = useState(GameState.DEFAULT);
  const sLayerRef = useRef(null);
  const { data: defaultAscii } = useQuery("default", () =>
    fetchAscii(defaultAsciiTxt)
  );
  const { data: smileAscii } = useQuery("smile", () =>
    fetchAscii(smileAsciiTxt)
  );
  const { data: frownAscii } = useQuery("frown", () =>
    fetchAscii(frownAsciiTxt)
  );
  const [ref, { width, height }] = useMeasure();

  let fontSize = 10;
  if (width && height) {
    fontSize = getFontSize(width, height);
  }
  const styles = {
    fontSize,
  };

  const asciiSelection = () => {
    if (gameState === GameState.SMILE) {
      return smileAscii;
    } else if (gameState === GameState.FROWN) {
      return frownAscii;
    } else {
      return defaultAscii;
    }
  };

  const mouseDown = (evt: React.MouseEvent) => {
    if (gameState === GameState.SMILE) return;

    setIsSGrabbing(true);

    const x = evt.nativeEvent.offsetX;
    const y = evt.nativeEvent.offsetY;
    setSPositionOffset([x, y]);
  };

  const mouseUp = () => {
    setIsSGrabbing(false);

    if (gameState === GameState.SMILE) {
      setGameState(GameState.FROWN);
      setSPosition([-15, -15]);
      setSPositionOffset([0, 0]);
      return;
    }

    if (!width || !height) return;

    const [x, y] = sPosition;
    const xPercent = x / width;
    const yPercent = y / height;

    if (
      xPercent > 0.1 &&
      xPercent < 0.3 &&
      yPercent > 0.25 &&
      yPercent < 0.63
    ) {
      onSuccess();
    }
  };

  const mouseMove = (evt: React.MouseEvent) => {
    if (!isSGrabbing || evt.target !== sLayerRef.current) return;

    const [xOffset, yOffset] = sPositionOffset;

    const x = evt.nativeEvent.offsetX;
    const y = evt.nativeEvent.offsetY;

    setSPosition([x - xOffset, y - yOffset]);
  };

  const onSuccess = () => {
    if (gameState === GameState.SMILE) {
      setGameState(GameState.FROWN);
    } else {
      setGameState(GameState.SMILE);
    }
  };

  const layerStyle = {
    cursor: isSGrabbing ? "grabbing" : "auto",
  };

  const imageStyle = {
    pointerEvents: isSGrabbing
      ? ("none" as React.CSSProperties["pointerEvents"])
      : ("all" as React.CSSProperties["pointerEvents"]),
    transform: `translate3d(${sPosition[0]}px, ${sPosition[1]}px, 0)`,
  };
  let imageClass = "sunglasses";
  if (isSGrabbing) imageClass += " grabbed";
  if (gameState !== GameState.SMILE) imageClass += " show";

  return (
    <div
      ref={ref}
      className="game-container"
      aria-label="An ASCII-art selfie of Connie made up of text characters."
    >
      <div
        aria-hidden="true"
        className="sunglasses-layer"
        onMouseUp={mouseUp}
        onMouseMove={mouseMove}
        style={layerStyle}
        ref={sLayerRef}
      >
        <img
          onMouseDown={mouseDown}
          draggable="false"
          style={imageStyle}
          className={imageClass}
          src={sunglassesSrc}
          alt=""
        />
      </div>
      <div className="ascii-layer" aria-hidden="true" style={styles}>
        <img
          draggable="false"
          alt=""
          className={
            gameState === GameState.SMILE ? "sunglasses show" : "sunglasses"
          }
          src={sunglassesSmileSrc}
        />
        {asciiSelection()}
      </div>
    </div>
  );
}

function Sunglasses() {
  return (
    <QueryClientProvider client={queryClient}>
      <main className="sunglasses">
        <SunglassesMain />
      </main>
    </QueryClientProvider>
  );
}

export default Sunglasses;
